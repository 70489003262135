import { IdsList, IdsLi, IdsLiText } from '@ids/react'
import { Navigation } from '@itau-loans-www/shopping/src/components'
import { submitOnChange } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from 'layouts/Proposal'
import { goTo } from 'utils'

import './Choose.scss'
import { propertyData } from './constants'
import usePropertyDataChoose from './hooks'

const Choose = ({ pageContext }) => {
  const { onSubmit, onLoadData } = usePropertyDataChoose()

  return (
    <ProposalLayout pageContext={pageContext}>
      <h1 className="proposal-title">
        Você tem algum imóvel quitado no seu nome?
      </h1>
      <IdsList className="choose__list">
        {propertyData.map(({ label, value, id }) => (
          <IdsLi
            data-testid={id}
            key={id}
            id={id}
            tagName="button"
            onClick={() => submitOnChange(onSubmit(value))}
          >
            <IdsLiText>{label}</IdsLiText>
          </IdsLi>
        ))}
      </IdsList>
      <Navigation
        prevStep={onLoadData.prevStep}
        prevButtonId="property-data-prev-btn"
        onPrevStepClick={() => goTo(onLoadData.prevStep)}
      />
    </ProposalLayout>
  )
}

export default Choose
