export const propertyData = [
  {
    label: 'Sim',
    id: 'sim',
    value: 'sim'
  },
  {
    label: 'Não',
    id: 'nao',
    value: 'nao'
  }
]
