import { useState, useEffect } from 'react'

import { useLead } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import services from '../services'

const usePropertyDataChoose = () => {
  const { changeLeadData } = useLead()

  const [onLoadData, setOnLoadData] = useState({
    prevStep: ''
  })

  useEffect(() => {
    const getData = async () => {
      try {
        const { previous_step } = await services.getProperty()

        changeLeadData({
          previousStep: previous_step
        })
        setOnLoadData({
          prevStep: previous_step
        })
      } catch (error) {
        const payload = {
          error: error?.message,
          requestURL: error?.request?.responseURL
        }

        DDPageError({
          name: 'usePropertyDataChoose | getData',
          payload
        })
      }
    }

    getData()
  }, [])

  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'proposta/dados-imovel' })
  }, [])

  const onSubmit = async (answer) => {
    if (answer === 'sim') {
      analytics.track('buttonClick', {
        currentPage: 'proposta/dados-imovel',
        detail: 'click-step-dados-imovel-sim'
      })
      changeLeadData({
        previousStep: 'property-data'
      })
      goTo('property-data-customize')
    } else {
      analytics.track('buttonClick', {
        currentPage: 'proposta/dados-imovel',
        detail: 'click-step-dados-imovel-nao'
      })

      const requestBody = {
        has_property: false,
        property_value: 0
      }

      try {
        const { next_step } = await services.putProperty(requestBody)
        goTo(next_step)
      } catch (error) {
        const payload = {
          error: error?.message,
          requestURL: error?.request?.responseURL
        }

        DDPageError({
          name: 'usePropertyDataChoose | putProperty',
          payload
        })
      }
    }
  }

  return { onSubmit, onLoadData }
}

export default usePropertyDataChoose
